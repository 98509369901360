<template>
    <v-card>
        <div class="mx-auto container container--fluid">
            <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
                <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
                <v-btn dark text @click="snackbar = false">Close</v-btn>
            </v-snackbar>

            <v-container>
                <v-toolbar-title>Import Year</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-row>
                    <v-col cols="5">
                        <v-file-input placeholder="CSV of Year data" prepend-icon="mdi-file" label="Year data" show-size v-model="file"></v-file-input>
                    </v-col>
                   
                        <v-btn @click="submitFile" :loading="output_load" class="ma-2">Submit</v-btn>
                          <a href="https://easypariksha.s3.ap-south-1.amazonaws.com/import_excel_csv_file/Import_Year_Tpo.csv">
                            <v-btn :loading="loading3" :disabled="loading3" color="success" class="ma-2 white--text downbtn" @click="loader = 'loading3'"> <v-icon left dark>mdi-cloud-download</v-icon>Download import CSV format </v-btn>
                        </a>
                    
                </v-row>
            </v-container>
             <v-toolbar flat color="white" dense>
                <v-toolbar-title>Year</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
            </v-toolbar>
            <v-divider class="mx-4"></v-divider>
            <v-app id="inspire" class>
                <template v-if="load==true">
                    <v-data-table :headers="headers" :items="year_list" class="elevation-1" :search="search">
                        <template v-slot:top>
                            <v-toolbar flat color="white">
                                <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
                                <v-spacer></v-spacer><span style="width: 20px;"></span>
                                <v-dialog v-model="dialog" max-width="500px">
                                    <template v-slot:activator="{ on }">
                                        <v-btn color="indigo darken-4" dark class="mb-2" v-on="on" @click="iseditable=!iseditable">Add New Year</v-btn>
                                    </template>
                                    <v-card>
                                        <v-card-title>
                                            <span class="headline">{{ formTitle }}</span>
                                        </v-card-title>

                                        <v-card-text>
                                            <v-container>
                                                <!-- <v-col cols="12" sm="12" md="12" v-if="editedIndex == -1">
                                                    <v-autocomplete :items="org_list" v-model="editedItem.organization" label="Select Organization" :rules="[v => !!v || 'required']"></v-autocomplete>
                                                </v-col>
                                                <v-col cols="12" v-if="editedIndex != -1">
                                                    Organization
                                                    <v-select
                                                        dense
                                                        v-model="editedItem.organization"
                                                        :items="org_list"
                                                        item-value="id"
                                                        persistent-hint
                                                        :error="errorMsg.organization?true:false"
                                                        :error-messages="errorMsg.organization?'Required':''"
                                                    ></v-select>
                                                </v-col> -->

                                                <v-col cols="12">
                                                    <v-text-field v-model="editedItem.name" label="Year *" :rules="[v => !!v || 'required']" dense></v-text-field>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-text-field dense v-model="editedItem.display_name" label="Display Name *" :rules="[v => !!v || 'required']"></v-text-field>
                                                    <span v-if="editedIndex==-1"></span>
                                                </v-col>
                                                <v-col>
                                                    <input type="hidden" v-model="editedItem.id" name="id" />
                                                    <!-- <v-hidden v-model="editedItem.id" label="id"></v-hidden> -->
                                                </v-col>
                                                <v-row>
                                                    <v-col cols="6" sm="3" md="2">
                                                        <v-checkbox v-model="editedItem.isactive" color="success" label="isActive?"></v-checkbox>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card-text>

                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                                            <v-btn color="primary darken-1" @click="save" :disabled="valid">Save</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </v-toolbar>
                        </template>
                        <!-- <template v-slot:item.isactive="{ item }">
                            <span v-if="item.isactive">
                                <v-icon class="mr-2 v-icon-size" color="success">mdi-check-circle-outline</v-icon>
                            </span>
                            <span v-else>
                                <v-icon class="mr-2 v-icon-size" color="error">mdi-close-circle-outline</v-icon>
                            </span>
                        </template> -->
                        <template v-slot:item.isactive="{ item }">
       
          <v-switch v-model="item.isactive" @click="activeswitch(item)"></v-switch>
        </template>
                        <template v-slot:item.action="{ item }">
                            <div>
                                <v-avatar style size="25" class="edit-avatar">
                                    <v-icon class="edit-v-icon mr-2" @click="editItem(item)">edit</v-icon>
                                </v-avatar>
                            </div>
                        </template>
                    </v-data-table>
                </template>
            </v-app>
        </div>
    </v-card>
</template>

<script>
    import axios from "axios";
    export default {
        data: () => ({
            valid: false,
            overlay: false,
            snackbar_msg: "",
            color: "",
            snackbar: false,
            iseditable: false,
            load: false,
            dialog: false,
            year_list: null,
            org_list: null,
            
            file: null,
            output_load: false,
            loading3: false,
            dialog_send: false,
            errorMsg: {
                organization: false,
            },
            search: "",
            headers: [
                {
                    text: "Year",
                    value: "name",
                },
                {
                    text: "Display Name",
                    value: "display_name",
                },
                {
                    text: "Organization",
                    value: "organization",
                },
               
                {
                    text: "Active",
                    value: "isactive",
                },
                {
                    text: "Actions",
                    value: "action",
                    sortable: false,
                },
            ],

            editedIndex: -1,
            editedItem: {
                id: 0,
                name: "",
                display_name: "",
                organization: "",
               
                isactive: true,
               
            },
            defaultItem: {
                id: 0,
                name: "",
                display_name: "",
                organization: "",
                
                isactive: true,
               
            },
        }),

        computed: {
            formTitle() {
                return this.editedIndex === -1 ? "New Year" : "Edit Year";
            },
        },

        watch: {
            dialog(val) {
                val || this.close();
            },
            overlay(val) {
                val = () => {
                    this.overlay = false;
                };
            },
        },
        mounted() {
            this.onload();
        },

        methods: {
            onload() {
                axios
                    .post("/AdminImport/importYearHome")
                    .then((res) => {
                        if (res.data.msg == "200") {
                            this.year_list = res.data.year_list;
                            this.org_list = res.data.org_list;
                            
                            this.load = true;
                        } else {
                                window.console.log(res.data.msg)
                            }
                })
                .catch(error => {
                            this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                            // window.console.log(error);
                        })
                        .finally(() => {
                            // 'use strict';
                            var overlay = false;
                        });
            },
            initializeErrorMsgFalse() {
                this.errorMsg.organization = false;
            },
            editItem(item) {
                this.iseditable = false;
                this.editedIndex = this.year_list.indexOf(item);
                this.editedItem = Object.assign({}, item);
                this.dialog = true;
            },
            showSnackbar(clr, msg) {
                this.snackbar = true;
                this.color = clr;
                this.snackbar_msg = msg;
            },
            close() {
                this.dialog = false;
                setTimeout(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.initializeErrorMsgFalse();
                    this.editedIndex = -1;
                }, 300);
            },
            submitFile() {
                if (this.file == null) {
                    this.showSnackbar("#b71c1c", "Please select the file!");
                    //alert("Please select the file!");
                } else if (this.checkExtention()) {
                    this.output_load = true;
                    let formData = new FormData();
                    formData.append("file", this.file);
                    this.dialog_send = true;
                    axios
                        .post("AdminImport/importYearFile", formData, {
                            headers: {
                                "Content-Type": "multipart/form-data",
                            },
                        })
                        .then((res) => {
                            if (res.data.msg == "200") {
                                this.load = true;
                                if (res.data.statuslist != "no_more_records") {
                                    this.dialog_send = false;
                                    this.output_load = false;
                                    this.loadData();
                                    this.showSnackbar("#4CAF50", "Year Imported Successfully...");
                                } else {
                                    this.dialog_send = false;
                                    this.output_load = false;
                                    this.showSnackbar("#b71c1c", "Uploaded CSV file is Empty!");
                                }
                            } else {
                                this.output_load = false;
                                this.dialog_send = false;
                                this.showSnackbar("#b71c1c", "Something went wrong!");
                            }
                        })
                        .catch(function () {
                            console.log("FAILURE!!");
                        })
                        .finally(() => {
                            this.output_load = false;
                        });
                    //     }
                } else {
                    this.showSnackbar("#b71c1c", "Please upload only CSV file!");
                }
            }, //end of the fuction
            loadData() {
                axios
                    .post("/AdminImport/importYearHome")
                    .then((res) => {
                        if (res.data.msg == "200") {
                            this.year_list = res.data.year_list;
                            this.org_list = res.data.org_list;
                            this.prg_list = res.data.prg_list;
                            this.dept_list = res.data.dept_list;
                            this.load = true;
                        } else {
                        }
                    })
                    .catch((error) => {
                        window.console.log(error);
                    });
            },
            checkExtention() {
                if (this.file != null) {
                    var file = this.file.name;
                    var ext = file.split(".").pop();
                    if (ext == "csv") {
                        return true;
                    } else {
                        // console.log("false")
                        // alert("Upload only CSV file!");
                        return false;
                    }
                } else {
                    return false;
                }
            },
            save() {
                if (this.editedItem.name == "") {
                    this.showSnackbar("red", "please Enter Year Name");
                } else if (this.editedItem.display_name == "") {
                    this.showSnackbar("red", "please Enter Year Display Name");
                }
                else{
                    if (this.editedIndex > -1) {
                        //  console.log("edit.." + this.editedItem);
                        this.iseditable = false;
                        axios
                            .post("/AdminImport/editYear", this.editedItem)
                            .then((res) => {
                                if (res.data.status.code == "SUCCESS") {
                                    Object.assign(this.year_list[this.editedIndex], this.editedItem);
                                    this.onload()
                                    this.showSnackbar("#4caf50", "Year Updated Successfully..."); // show snackbar on success
                                } else if (res.data.status.code == "NA") {
                                    //alert(res.data.status.message)
                                    this.showSnackbar("#b71c1c", "Year Already Present!!!"); // show snackbar on error
                                }
                            })
                            .catch((error) => {
                                this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                                // window.console.log(error);
                            });
                    } else {
                        axios
                            .post("/AdminImport/saveYear", this.editedItem)
                            .then((res) => {
                                if (res.data.status.code == "SUCCESS") {
                                    this.year_list.push(this.editedItem);
                                    this.onload()
                                    this.showSnackbar("#4caf50", "New Year Added Successfully!!!"); // show snackbar on success
                                } else if (res.data.status.code == "NA") {
                                    //alert(res.data.status.message)
                                    this.showSnackbar("#b71c1c", "Year Already Present!!!"); // show snackbar on error
                                }
                            })
                            .catch((error) => {
                                window.console.log(error);
                            });
                    }
                    this.close();
                }    
            },
             activeswitch(item) {
     
      const data = {
        deleteitem: item.id,
      };
    //   console.log(item.id);

      axios
        .post("/AdminImport/activeYear", data)
        .then((res) => {
        //   console.log("res.data");
        //   console.log(res.data);
          if (res.data.msg == "200") {
            // console.log("res.data");
            // console.log(res.data);
            // console.log("success");
            this.showSnackbar("#4caf50", res.data.message);
            this.onLoad();
          } else {
            // console.log("fail");
            this.showSnackbar("#b71c1c", res.data.msg);
          }
        })
        .catch((error) => {
          window.console.log(error);
        });
      this.close();
    },
            isValidated() {
                if (this.editedItem.organization) {
                    return true;
                } else {
                    if (!this.editedItem.organization) {
                        this.errorMsg.organization = true;
                    }

                    return false;
                }
            }, // .....end of isValidated()
        },
    };
</script>
<style scoped>
    .v-text-field {
        padding-top: 0px;
        margin-top: 0px;
    }

    .v-icon-size {
        font-size: 20px;
    }

    .edit-avatar {
        transition: 0.3s;
        border: 1px solid #b0bec5;
    }
    .downbtn {
        float: right;
    }

    .edit-avatar:hover {
        background-color: #b0bec5;
    }

    .edit-v-icon {
        padding-left: 6px;
        font-size: 15px;
    }

    .edit-v-icon:hover {
        color: white;
    }
</style>
